import { render, staticRenderFns } from "./DnsHostDetail.vue?vue&type=template&id=71e8701c&scoped=true"
import script from "./DnsHostDetail.vue?vue&type=script&lang=js"
export * from "./DnsHostDetail.vue?vue&type=script&lang=js"
import style0 from "./DnsHostDetail.vue?vue&type=style&index=0&id=71e8701c&prod&lang=stylus&scoped=true"
import style1 from "./DnsHostDetail.vue?vue&type=style&index=1&id=71e8701c&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e8701c",
  null
  
)

/* custom blocks */
import block0 from "./DnsHostDetail.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports